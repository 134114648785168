import React from 'react';

import { ClassNameProps, HTMLElementProps } from '@ComponentProps';
import { ColorsPalette } from '@UX/themes/types';

interface BackgroundWrapperProps extends ClassNameProps, HTMLElementProps {
  bgColor: keyof ColorsPalette;
}

export const BackgroundWrapper: React.FC<BackgroundWrapperProps> = ({
  bgColor,
  children,
  className,
}) => (
  <div
    className={className}
    sx={{
      width: '100%',
      backgroundColor: bgColor,
    }}
  >
    {children}
  </div>
);
