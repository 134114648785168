import React from 'react';

import { ClassNameProps, HTMLElementProps } from '@ComponentProps';
import { BackgroundWrapper } from '@UX/BackgroundWrapper/BackgroundWrapper';
import { ColorsPalette } from '@UX/themes/types';

export interface LandingBackgroundWrapperProps extends ClassNameProps, HTMLElementProps {
  bgColor?: Extract<
    keyof ColorsPalette,
    'white' | 'landingPageBackgroundWrapper' | 'backgroundLightsubtle'
  >;
}
export const LandingBackgroundWrapper: React.FC<LandingBackgroundWrapperProps> = ({
  className,
  children,
  bgColor = 'white',
}) => (
  <BackgroundWrapper
    className={className}
    bgColor={bgColor}
    sx={{
      paddingY: ['3xl'],
    }}
  >
    {children}
  </BackgroundWrapper>
);
